var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayToolbar
    ? _c(
        "v-toolbar",
        { attrs: { id: "tickets-mobile-data-toolbar", flat: "" } },
        [_c("v-spacer")],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-toolbar v-if="displayToolbar" id="tickets-mobile-data-toolbar" flat>
    <!-- Hide this toolbar checkbox until feature is complete -->
    <!-- <div class="checkbox-container">
            <slot name="selectCheckbox" />          
                            
        </div> -->
    <v-spacer />
    <!-- <PrintTicketsDialog
            :selected-tickets="selectedTickets"
        />        -->
  </v-toolbar>
</template>
<script>
export default {
  components: {},
  props: {
    displayToolbar: {
      type: Boolean,
    },
    selectedTickets: {
      type: Array,
      default: () => [],
    },
    differencesDisabled: {
      type: Boolean,
    },
    respondDisabled: {
      type: Boolean,
    },
    assignDisabled: {
      type: Boolean,
    },
  },
  data: () => ({
    items: [],
    value: false,
    indeterminate: true,
  }),
  methods: {},
};
</script>
<style>
#ticket-cards .checkbox-container {
  display: flex;
  padding-top: 16px;
}
</style>
